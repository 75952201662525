var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',[_c('content-header',{attrs:{"title":_vm.$t('contactUs')}}),_c('validation-observer',{ref:"formRef",attrs:{"novalidate":""}},[_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"main-page-container"},[_c('div',{staticClass:"row"},[_c('sub-header',{attrs:{"title":_vm.$t('contactForm')}})],1),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('validation-provider',{attrs:{"rules":"required|max:50"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('base-input',{attrs:{"error":errors[0],"error-msg":_vm.$t('invalidField'),"label":_vm.$t('name'),"maxLength":50},model:{value:(_vm.model.clientName),callback:function ($$v) {_vm.$set(_vm.model, "clientName", $$v)},expression:"model.clientName"}})]}}])})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('validation-provider',{attrs:{"rules":{
                    numeric: true,
                    required: _vm.model.clientPhone,
                  }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('base-input',{attrs:{"error":errors[0],"error-msg":_vm.$t('phoneRequired'),"label":_vm.$t('phone'),"maxLength":15,"mask-type":"Phone"},model:{value:(_vm.model.clientPhone),callback:function ($$v) {_vm.$set(_vm.model, "clientPhone", $$v)},expression:"model.clientPhone"}})]}}])})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('validation-provider',{attrs:{"rules":{
                    email: true,
                    required: _vm.model.clientEmail,
                  }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('base-input',{attrs:{"error":errors[0],"error-msg":_vm.$t('invalidField'),"label":_vm.$t('email'),"maxLength":60},model:{value:(_vm.model.clientEmail),callback:function ($$v) {_vm.$set(_vm.model, "clientEmail", $$v)},expression:"model.clientEmail"}})]}}])})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('base-input',{attrs:{"error":errors[0],"error-msg":_vm.$t('invalidField'),"textarea":true,"label":_vm.$t('message')},model:{value:(_vm.model.clientMessage),callback:function ($$v) {_vm.$set(_vm.model, "clientMessage", $$v)},expression:"model.clientMessage"}})]}}])})],1)])]),_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"col"},[_c('p',[_c('b',[_c('label',{staticClass:"color-black inf",domProps:{"innerHTML":_vm._s(_vm.$t('contactInfornation'))}})])]),_c('p',[_c('b',[_c('label',{staticClass:"color-black",domProps:{"innerHTML":_vm._s(_vm.$t('contactinformationRequest'))}}),_vm._v(" : ")]),_c('label',{staticClass:"text-primary",domProps:{"innerHTML":_vm._s(_vm.contactInfo.contactEmail)}})]),_c('p',[_c('b',[_c('label',{staticClass:"color-black",domProps:{"innerHTML":_vm._s(_vm.$t('contactstationName'))}}),_vm._v(" : ")]),_c('label',{staticClass:"text-primary",domProps:{"innerHTML":_vm._s(_vm.contactInfo.contactPhone)}})])])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('button',{staticClass:"btn btn-primary float-left",on:{"click":function($event){return _vm.sendNotification()}}},[_c('i',{staticClass:"far fa-paper-plane"}),_vm._v(" "+_vm._s(_vm.$t('sendMessage'))+" ")])])])])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }