<template>
  <section>
    <content-header :title="$t('contactUs')" />
    <validation-observer ref="formRef" novalidate>
      <div class="container-fluid">
        <div class="main-page-container">
          <div class="row">
            <sub-header :title="$t('contactForm')" />
          </div>
          <div class="row">
            <div class="col-md-6">
              <div class="row">
                <div class="col">
                  <validation-provider v-slot="{ errors }" rules="required|max:50">
                    <base-input
                      v-model="model.clientName"
                      :error="errors[0]"
                      :error-msg="$t('invalidField')"
                      :label="$t('name')"
                      :maxLength="50"
                    />
                  </validation-provider>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <validation-provider
                    v-slot="{ errors }"
                    :rules="{
                      numeric: true,
                      required: model.clientPhone,
                    }"
                  >
                    <base-input
                      v-model="model.clientPhone"
                      :error="errors[0]"
                      :error-msg="$t('phoneRequired')"
                      :label="$t('phone')"
                      :maxLength="15"
                      mask-type="Phone"
                    />
                  </validation-provider>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <validation-provider
                    v-slot="{ errors }"
                    :rules="{
                      email: true,
                      required: model.clientEmail,
                    }"
                  >
                    <base-input
                      v-model="model.clientEmail"
                      :error="errors[0]"
                      :error-msg="$t('invalidField')"
                      :label="$t('email')"
                      :maxLength="60"
                    />
                  </validation-provider>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <validation-provider v-slot="{ errors }" rules="required">
                    <base-input
                      v-model="model.clientMessage"
                      :error="errors[0]"
                      :error-msg="$t('invalidField')"
                      :textarea="true"
                      :label="$t('message')"
                    />
                  </validation-provider>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="col">
                <p>
                  <b>
                    <label
                      class="color-black inf"
                      v-html="$t('contactInfornation')"
                    />
                  </b>
                </p>
                <p>
                  <b>
                    <label
                      class="color-black"
                      v-html="$t('contactinformationRequest')"
                    />
                    :
                  </b>
                  <label class="text-primary" v-html="contactInfo.contactEmail" />
                </p>
                <p>
                  <b>
                    <label
                      class="color-black"
                      v-html="$t('contactstationName')"
                    />
                    :
                  </b>
                  <label class="text-primary" v-html="contactInfo.contactPhone" />
                </p>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <button
                class="btn btn-primary float-left"
                @click="sendNotification()"
              >
                <i class="far fa-paper-plane" /> {{ $t('sendMessage') }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </validation-observer>
  </section>
</template>

<script>
import BaseInput from '@/components/BaseInput.vue';
import contentHeader from '@/components/Header';
import SubHeader from '@/components/SubHeader';
import { sendNotification as _sendNotification,
  getContactInfo as _getContactInfo
 } from '@/services/ContactService';
import { modulesName } from '@/store';
import { mapGetters } from 'vuex';

export default {
  name: 'contact',
  components: {
    BaseInput,
    contentHeader,
    SubHeader,
  },
  data() {
    return {
      contactInfo: {
        contactPhone: null,
        contactEmail: null
      },
      model: {
        clientName: null,
        clientPhone: null,
        clientEmail: null,
        clientMessage: null,
      }
    };
  },
  async mounted() {
    await _getContactInfo(this.fullProfile.companyId).then(({data}) => {
      this.contactInfo = data;
    });
  },
  computed: {
    ...mapGetters(modulesName.userModuleName, ['fullProfile']),
  },
  methods: {
    async sendNotification() {

     if (!(await this.$refs.formRef.validate())) return;

     await _sendNotification({ ...this.model })
        .then(() => {
          this.ShowSuccessSaveToast();

          this.model.clientName = null;
          this.model.clientPhone = null;
          this.model.clientEmail = null;
          this.model.clientMessage = null;
        })
        .catch((error) => this.ShowErrorToast(error.response.data.message));
        
    },
  },
};
</script>

<style lang="scss" scoped>
.main-page-container {
  max-width: 768px;
}
textarea {
  resize: none;
}
.inf {
  font-size: 20px;
}
</style>
